import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { I18nContextType } from '../i18n/context';
import PlasticSurgeryContainer from '../components/plastic-surgery/PlasticSurgeryContainer';
import PlasticSurgeryMenu from '../components/plastic-surgery/PlasticSurgeryMenu';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import plasticSurgeryBreakpoint from '../components/plastic-surgery/plasticSurgeryBreakpoint';
import { useI18n } from '../i18n/useI18n';
import {
    PlasticSurgeryMenuContext,
    PlasticSurgeryMenuElement,
} from '../components/plastic-surgery/PlasticSurgeryMenuContext';
import { constructPath } from '../i18n/util';

export interface PlasticSurgeryProps {
    pageContext: {
        i18n: I18nContextType;
        plasticSurgeryMenu: PlasticSurgeryMenuContext;
    };
    data: {
        datoCmsPlasticSurgeryPage: {
            title: string;
            subtitle: string;
            seoMetaTags: any;
        };
    };
}

const PlasticSurgeryPage: React.FC<PlasticSurgeryProps> = ({ data, pageContext }) => {
    const { language } = useI18n();
    const { plasticSurgeryMenu } = pageContext;

    useEffect(() => {
        if (window.innerWidth >= plasticSurgeryBreakpoint) {
            const slug = (plasticSurgeryMenu[0] as PlasticSurgeryMenuElement).slug;
            navigate(constructPath('plastic-surgery', language) + '/' + slug, { replace: true });
        }
    }, []);

    return (
        <>
            <HelmetDatoCms seo={data.datoCmsPlasticSurgeryPage.seoMetaTags} />
            <PlasticSurgeryContainer>
                <PlasticSurgeryMenu
                    title={data.datoCmsPlasticSurgeryPage.title}
                    subtitle={data.datoCmsPlasticSurgeryPage.subtitle}
                    plasticSurgeryMenu={plasticSurgeryMenu}
                    isOnMenuPage
                />
            </PlasticSurgeryContainer>
        </>
    );
};

export const query = graphql`
    query PlasticSurgeryPage($language: String!) {
        datoCmsPlasticSurgeryPage(locale: { eq: $language }) {
            title
            subtitle
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
    }
`;

export default PlasticSurgeryPage;
